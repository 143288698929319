import Accordion from './Accordion';
import NextImage from 'next/image';
import PropTypes from 'prop-types';
import Heading from '@/atoms/Heading';

const ToggleList = ({ list, onClick }) => {
    return (
        <ul className="flex justify-center lg:flex-col gap-4 lg:gap-6 w-full">
            {list?.map(({ label, active, id }, index) => (
                <li
                    key={id}
                    className={`text-sm font-medium ${active ? 'text-[#4F3BCC]' : 'text-grey-shade3 cursor-pointer'} flex justify-between items-center`}
                    onClick={() => onClick(id)}
                    data-aos="fade-right"
                    data-aos-delay={index * 100}
                    data-aos-once="true"
                >
                    {label}
                    {active && (
                        <div className="hidden lg:flex">
                            <NextImage
                                src="/images/icons/icon_arrow_blue_right.svg"
                                alt="faq"
                                width={16}
                                height={14}
                            />
                        </div>
                    )}
                </li>
            ))}
        </ul>
    );
};

const Faqs = ({
    isHeading = true,
    tabClass = '',
    tabListClass = '',
    isFaqImg = true,
    list,
    handleToggleList,
    isFullWidth = false,
}) => {
    return (
        <div id="faq" className="container">
            <div
                className={` ${!isFullWidth ? 'pt-8 md:pt-10 lg:pr-[100px] px-4 md:px-10 lg:px-5 bg-grey-shade6 rounded-xl ' : ''} flex flex-wrap lg:flex-nowrap gap-6 md:gap-8 lg:gap-5 pb-8 md:pb-10 ${tabClass}`}
            >
                <div
                    className={`flex flex-col px-3 w-full rounded-xl lg:rounded-lg ${isFullWidth ? 'sm:items-start lg:max-w-[180px]' : 'sm:items-end lg:max-w-[240px]'} ${tabListClass}`}
                >
                    <div
                        className={`max-auto overflow-hidden ${isFullWidth ? 'w-full lg:min-h-[350px]' : 'lg:max-w-[120px] w-full lg:pr-4'} `}
                    >
                        {isHeading && (
                            <Heading
                                type="h1"
                                fontColor="text-grey-shade-1"
                                className={`mb-2 lg:mb-8 ${isFullWidth ? 'pr-0' : 'lg:pr-7'}`}
                                fontFamily="font-sectionHeading"
                                align="center"
                            >
                                FAQs
                            </Heading>
                        )}
                        <ToggleList list={list} onClick={handleToggleList} />
                    </div>
                    {isFaqImg && (
                        <div className="hidden lg:flex">
                            <NextImage
                                src="/images/icon_faq.svg"
                                alt="faq"
                                width={200}
                                height={198}
                                className="mt-[50px]"
                            />
                        </div>
                    )}
                </div>
                <div className="w-full text-">
                    {list.map(
                        ({ active, sections }) =>
                            active && (
                                <Accordion
                                    key={list.id}
                                    accordionData={sections}
                                />
                            )
                    )}
                </div>
            </div>
        </div>
    );
};

ToggleList.propTypes = {
    list: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            label: PropTypes.string.isRequired,
            active: PropTypes.bool.isRequired,
            sections: PropTypes.arrayOf(
                PropTypes.shape({
                    content: PropTypes.string.isRequired,
                    title: PropTypes.string.isRequired,
                })
            ).isRequired,
        })
    ).isRequired,
    onClick: PropTypes.func.isRequired,
};

Faqs.propTypes = {
    isHeading: PropTypes.bool,
    list: ToggleList.propTypes.list,
    handleToggleList: PropTypes.func.isRequired,
    tabClass: PropTypes.string,
    tabListClass: PropTypes.string,
    isFaqImg: PropTypes.bool,
    isFullWidth: PropTypes.bool,
};

export default Faqs;
